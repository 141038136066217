export const accordion = class {
  constructor() {
    $(document).on('click', '.article-title', function() {
      /*クリックでコンテンツを開閉*/
      $(this).next().slideToggle(400);
      /*矢印の向きを変更*/
      $(this).toggleClass('open');
    });
  }
}
