/*==============================================
セレクトボックス
==============================================*/

export const selectBox = class {
  constructor($target) {
    var $select = $target;
    var winW = $(window).width();
    var devW = 767;
    if (winW <= devW) {
      //767px以下の時の処理
    } else {
      //768pxより大きい時の処理

      $select.each(function() {
        if ($(this).hasClass('cancel-script')) return;

        if($(this).hasClass('template-message-select')){
          var $self = $(this),
          numberOptions = $(this).children('option').length;
          
          $self.addClass('select-hidden');
          $self.wrap('<div class="select select-scout-tmp"></div>');
          $self.after('<div class="select-styled msg-select-styled"></div>');
    
          var $tmpStyledSelect = $self.next('div.select-styled');
          $tmpStyledSelect.text($self.children('option').eq(0).text());
          if ($self.children('option:selected').length) {
            $tmpStyledSelect.text($self.children('option:selected').eq(0).first().text());
          } else {
            $tmpStyledSelect.text($self.children('option').eq(0).text());
          }
    
    
          var $tmpMsgList = $('<ul />', {
            'class': 'select-options msg-select-options'
          }).insertAfter($tmpStyledSelect);
    
          for (var i = 0; i < numberOptions; i++) {
            let isDisabled = $self.children('option').eq(i).hasClass('isDisabled')
            if(isDisabled){
              $self.children('option').eq(i).css('display', 'none')
            }
            if ($self.children('option').eq(i).prop('selected') && !isDisabled) {
              $('<li />', {
                rel: $self.children('option').eq(i).val(),
                class: `selected temp-item temp-list-${i}`,
                'data-list-id': i,
              }).appendTo($tmpMsgList);
    
            } else{
              $('<li />', {
                rel: $self.children('option').eq(i).val(),
                class: `temp-item temp-list-${i}`,
                'data-list-id': i,
              }).appendTo($tmpMsgList);
        
            }
    
          }
    
          var $tmpLists = $tmpMsgList.children('li');
          var num = 0
          for(const list of $tmpLists){
            $('<p />', {
              text: $self.children('option').eq(num).text(),
              class: 'temp-title'
            }).appendTo(list);
    
            if(!$(list).hasClass('temp-list-0')){
              $('<a />', {
                text: '',
                class: `temp-edit-btn tmp-btn-${num}`
              }).appendTo(list);
    
              //$(`.tmp-btn-${num}`).append(`<ul class='tmp-sub-menu'><li class='tmp-sub-edit'><%= link_to '編集', employers_scouts_send_tmp_edit_modal_path(), method: :post, remote: true%></li><li class='tmp-sub-delete'><a>削除</a></li></ul>`)
              $(`.tmp-btn-${num}`).append(`<ul class='tmp-sub-menu'><li class='js-modal-show-edit-open'><a class='modal-msg-tmp-edit-open'>編集</a></li><li class='js-modal-show-delete-open'><a class='modal-msg-tmp-delete-open' data-delete-id="${$self.children('option').eq(num).attr('data-id')}">削除</a></li></ul>`)
            }
    
            num++
          }  
    
          // セレクトタグ
          var $templateSelect = $('#js-template-message');
          var $tmpStyledSelected = $('.msg-select-styled');
          var $tmpMsgLists = $('.msg-select-options');
          var $tmpMsgListItems = $tmpMsgLists.children('li');
    
          // 文面テンプレートセレクトボックス
          $(document).on("click", ".msg-select-styled", function(e) {
            e.stopPropagation();
            $('div.select-styled.active').not(this).each(function() {
              $(this).removeClass('active').next('ul.select-options').hide();
            });
            $(this).toggleClass('active').next('ul.select-options').toggle();
          });
          
    
          // 文面テンプレート選択時のイベント
          $(document).on('click', ".temp-item", function(e) {
            e.stopPropagation();
            const _this = $(this)
            $tmpMsgListItems.removeClass('selected');
            $templateSelect.children().each(function(index, elem) {
              $(elem).removeClass('isSelect')
            })
    
            // $templateSelect.removeClass('isSelect');
            $tmpStyledSelect.text($(this).find('.temp-title').text()).change().removeClass('active');
            $(this).addClass('selected');
            var selectNode = $templateSelect.children().filter(function(index, elem) {
              if($(elem).data('id') === Number(_this.attr('rel'))){
                return elem
              }
            })
            $(selectNode[0]).addClass('isSelect');
    
            // 文面に選択したテキストをセット
            const $tmpOptions = $('#js-template-message').children('option');
            $tmpOptions.each(function(index, elem) {
                // $(elem).parents('#VFE008-01')
                if($(elem).hasClass('isSelect')){
                  const tmpContent = $(elem).attr('data-content')
                  $('#scout_content').val(tmpContent)
                }
            })
    
            $templateSelect.val($(this).attr('rel'));
            $tmpMsgLists.hide();
          });
    
    
          $(document).click(function() {
            $tmpStyledSelect.removeClass('active');
            $tmpMsgList.hide();
          });

        }else {

          var $this = $(this),
            numberOfOptions = $(this).children('option').length;
  
          $this.addClass('select-hidden');
          $this.wrap('<div class="select"></div>');
          $this.after('<div class="select-styled"></div>');
  
          var $styledSelect = $this.next('div.select-styled');
          $styledSelect.text($this.children('option').eq(0).text());
          if ($this.children('option:selected').length) {
            $styledSelect.text($this.children('option:selected').eq(0).text());
          } else {
            $styledSelect.text($this.children('option').eq(0).text());
          }
  
  
          var $list = $('<ul />', {
            'class': 'select-options'
          }).insertAfter($styledSelect);
  
          for (var i = 0; i < numberOfOptions; i++) {
            if ( $this.children('option').eq(i).prop('selected') ) {
              $('<li />', {
                text: $this.children('option').eq(i).text(),
                rel: $this.children('option').eq(i).val(),
                class: 'selected'
              }).appendTo($list);
            } else {
              $('<li />', {
                text: $this.children('option').eq(i).text(),
                rel: $this.children('option').eq(i).val()
              }).appendTo($list);
            }
          }
  
          var $listItems = $list.children('li');
  
          $styledSelect.click(function(e) {
            e.stopPropagation();
            $('div.select-styled.active').not(this).each(function() {
              $(this).removeClass('active').next('ul.select-options').hide();
            });
            $(this).toggleClass('active').next('ul.select-options').toggle();
          });
  
          $listItems.click(function(e) {
            $listItems.removeClass('selected');
            e.stopPropagation();
            $styledSelect.text($(this).text()).removeClass('active');
            $(this).addClass('selected');
            $this.val($(this).attr('rel'));
            $list.hide();
          });
  
          $(document).click(function() {
            $styledSelect.removeClass('active');
            $list.hide();
          });
        }
      });
    }
  }
}
