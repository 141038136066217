import { selectBox } from '../select_box.js';
import { accordion } from '../accordion.js';

const eventsInit = class {
  constructor() {
  /*==============================================
  ハンバーガーメニュー
  ==============================================*/
    $('.navToggle').off('click');
    $('.navToggle').on('click', function() {
      $(this).toggleClass('active');

      if ($(this).hasClass('active')) {
        $('.globalMenuSp').addClass('active');
      } else {
        $('.globalMenuSp').removeClass('active');
      }
    });
  /*==============================================
  モーダル
  ==============================================*/
    $('.modal01-open').on('click', function() {
      $('.modal01').fadeIn();
      return false;
    });
    $('.modal02-open').on('click', function() {
      $('.modal02').fadeIn();
      return false;
    });
    $('.modal03-open').on('click', function() {
      $('.modal03').fadeIn();
      return false;
    });
    $('.modal04-open').on('click', function() {
      $('.modal04').fadeIn();
      return false;
    });
    $('.js-modal-close').on('click', function() {
      $('.js-modal').fadeOut();
      return false;
    });
  /*==============================================
  VFJ009-02 チェックでグレーアウト
  ==============================================*/
    $('input[name="check"]').click(function() {
      $(this).closest('td').find('.box_02').toggleClass('nohover');
    });

  /*==============================================
  メッセージのタブ
  ==============================================*/
    $('.messe').click(function() {
      $('.area').removeClass('open');
    });

  /*==============================================
  全てチェック
  ==============================================*/
    $('.check_all').on('change', function() {
      $('.' + this.id).prop('checked', this.checked);
    });
  /*==============================================
  カレンダー
  ==============================================*/
    $("[id^=datepicker_0]").each(function(){
      $(this).datapicker();
    });
  /*==============================================
  ツールチップ 
  ==============================================*/
    $(document).on('click', function(event) {
      if(!$(event.target).closest('.trigger').length) {
        $('.tooltip').hide();
        $('.triangle').hide();
      } else {
        if($('.tooltip').css('display') == 'block') {
          $('.tooltip').hide();
          $('.triangle').hide();
        } else {
          $('.tooltip').show();
          $('.triangle').show();
        }
      }
    });

    $(document).on('click', function(event) {
      if($(event.target).closest('.tooltip2').length) {
        return false
      }

      if(!$(event.target).closest('.trigger2').length) {
        $('.tooltip2').hide();
        $('.triangle2').hide();
      } else {
        if($('.tooltip2').css('display') == 'block') {
          $('.tooltip2').hide();
          $('.triangle2').hide();
        } else {
          $('.tooltip2').show();
          $('.triangle2').show();
        }
      }
    });
  }
}

// ------------------------------------------------------------------
// CONTROLLER
// ------------------------------------------------------------------
$(window).on('load', function() {
  var events = new eventsInit();
  // セレクトボックス
  var selectBoxObj = new selectBox($('select'));
  // アコーディオン
  var accordionObj = new accordion();
});
